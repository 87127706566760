<template>
  <v-row justify="center">
    <v-flex
        md12
    >
      <v-card-text class="text--primary"
                   style="text-align: center!important;font-size: 18px !important;margin-top: 50px">

        <v-icon style="color: green;font-size: 200px">mdi-progress-check</v-icon>
        <h1 style="padding-bottom: 100px;padding-top: 100px;color: teal">{{ $t("paidSuccess") }}</h1>
        <h2 style="padding-bottom: 100px;color: teal">{{ $t("thankYou") }}</h2>
      </v-card-text>
    </v-flex>
  </v-row>
</template>

<script>

import {provider} from "@/service/provider";
import axios from "axios"

export default {
  name: "Payment",
  data() {
    return {}
  },

  methods: {
    async getUserById(userId) {
      let vm = this;
      let url = provider.baseURL + `/users/byId?id=${userId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            res => {
              if (res.data.code === 201) {
                let doc = res.data.data;
                vm.$store.state.userDoc = doc;
                localStorage.id = doc._id;
                localStorage.username = doc.username;
                localStorage.url = doc.url || localStorage.url || "";
                localStorage.userType = doc.userType || "";
                localStorage.phoneNumber = doc.phoneNumber || "";
                localStorage.fullName = doc.profile && doc.profile.fullName || "";

                if (doc.country || localStorage.country) {
                  localStorage.country = doc.country || localStorage.country;
                }
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    let vm = this;
    setTimeout(function () {
      vm.$router.push({name: "web-home"});
    }, 2000)
    vm.getUserById(localStorage.id);

  }
}

</script>